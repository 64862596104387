@import "~@/styles/variables/variables.scss";



















































































































































































































































































.water-home-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 12px;
	overflow-y: scroll;
	.top-region-pick {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 12px;
	}
	.content {
		display: flex;
		justify-content: space-between;
		.modal {
			width: calc(50% - 8px);
			.statistic-card {
				background-color: #cbd8ff;
				border-radius: 12px;
				display: flex;
				justify-content: space-around;
				.left-icon {
					position: relative;
					top: -24px;
					padding-left: 36px;
					img {
						width: 140px;
					}
					.cn-name {
						color: #474d59;
						font-size: 24px;
						font-weight: 500;
						letter-spacing: 3px;
						line-height: 36px;
						padding-left: 16px;
						white-space: nowrap;
					}
					.en-name {
						color: rgb(131, 137, 154);
						font-size: 16px;
						line-height: 24px;
						letter-spacing: 1px;
						padding-left: 16px;
						white-space: nowrap;
					}
				}
				.right-data {
					display: flex;
					align-items: center;
					.label-item {
						text-align: center;
						margin: 12px 24px 0;
						cursor: pointer;
						.title {
							color: #3f435e;
							font-size: 20px;
							line-height: 30px;
						}
						.value {
							color: #4d6bff;
							font-weight: 700;
							line-height: 37px;
							font-size: 32px;
							margin-top: 12px;
							font-family: DIN-Regular-2;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-page {
		.content {
			.modal {
				.statistic-card {
					.left-icon {
						width: 100px;
						padding-left: 12px;
						img {
							width: 100px;
						}
						.cn-name {
							font-size: 18px;
						}
						.en-name {
							font-size: 12px;
						}
					}
					.right-data {
						.label-item {
							margin: 8px 12px 0;
							.title {
								font-size: 16px;
							}
							.value {
								font-size: 26px;
							}
						}
					}
				}
			}
		}
	}
}

::v-deep {
	.gc-blank-page {
		margin-top: 20px;
	}
}
