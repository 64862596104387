@import "~@/styles/variables/variables.scss";

















































































































































































































.water-home-meter-line {
	background-color: #f2f4fc;
	padding: 12px;
	border-radius: 12px;
	margin-top: 16px;
	.modal {
		.chart {
			width: 100%;
			height: 245px;
		}
		& + .modal {
			margin-top: 12px;
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-meter-line {
		margin-top: 16px;
		.modal {
			.chart {
				width: 100%;
				height: 175px;
			}
			& + .modal {
				margin-top: 8px;
			}
		}
	}
}
