@import "~@/styles/variables/variables.scss";

















































































































































































































.water-home-manufacturer-pie {
	.pie-modal {
		display: flex;
		padding: 12px;
		align-items: flex-start;
		.charts {
			width: 214px;
			height: 214px;
			margin-top: 24px;
		}
		.info {
			flex: 1;
			.table-header {
				display: flex;
				.th {
					padding: 12px;
					flex: 1;
					p {
						font-size: 16px;
						color: #3f435e;
						font-weight: 500;
						&.right {
							text-align: right;
						}
					}
				}
			}
			.table-body {
				.tr {
					display: flex;
					.td {
						flex: 1;
						padding: 12px;
						p {
							color: #3f435e;
							&.right {
								text-align: right;
								font-family: DIN-Regular-2;
								font-size: 16px;
								font-weight: 700;
							}
							span {
								font-size: 14px;
								margin-left: 6px;
							}
							.icon {
								width: 12px;
								height: 12px;
								border-radius: 3px;
								display: inline-block;
								position: relative;
								top: 1px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-manufacturer-pie {
		.pie-modal {
			padding: 0 12px;
			.charts {
				width: 180px;
				height: 180px;
				margin-top: 12px;
			}
			.info {
				.table-header {
					.th {
						padding: 8px 12px;
						p {
							font-size: 15px;
						}
					}
				}
				.table-body {
					.tr {
						.td {
							padding: 7px 12px;
							p {
								&.right {
									font-size: 14px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}
}
