@import "~@/styles/variables/variables.scss";






































































































.content {
	display: flex;
	flex-direction: column;
	.tabs-box {
		display: flex;
		position: relative;
		.tab-item {
			font-size: 16px;
			color: #7b7e97;
			margin-right: 24px;
			line-height: 28px;
			&.active {
				color: #4d6bff;
			}
		}
		.underline {
			position: absolute;
			bottom: 0;
			width: 65px;
			height: 3px;
			background: linear-gradient(90deg, #4d6bff 0%, rgba(77, 107, 255, 0) 100%);
		}
	}
	.gc-table {
		padding-top: 12px;
		flex: 1;
		height: 0;
	}
}
