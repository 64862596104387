@import "~@/styles/variables/variables.scss";
































































































.water-home-device {
	margin: 12px 0;
	.table-content {
		margin-top: 8px;
		.table-header {
			display: flex;
			margin-bottom: 6px;
			.th {
				flex: 1;
				padding: 12px;
				p {
					color: #282c42;
					font-size: 12px;
					font-weight: 700;
					line-height: 18px;
					text-align: center;
				}
			}
		}
		.table-body {
			max-height: 216px;
			overflow-y: scroll;
			.tr {
				display: flex;
				background-color: #f6f8ff;
				border-radius: 6px;
				& + .tr {
					margin-top: 12px;
				}
				.td {
					padding: 14px 12px;
					flex: 1;
					p {
						color: #3f435e;
						font-size: 14px;
						text-align: center;
						&.number {
							color: #4d6bff;
							font-family: DIN-Regular-2;
							text-decoration: underline;
							cursor: pointer;
						}
						&.int {
							font-size: 17px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-device {
		.table-content {
			.table-body {
				max-height: 140px;
				.tr {
					& + .tr {
						margin-top: 6px;
					}
				}
			}
		}
	}
}
